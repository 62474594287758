import Info from "../../components/Info/Info";
import React, { useEffect, useRef } from "react";
import GameCart from "../../components/GameCart/GameCart";
import GiftCart from "../../components/GiftCart/GiftCart";
import Slider from "../../components/Slider/Slider";
import Work from "../../components/Work/Work";
import Reviews from "../../components/Reviews/Reviews";
import { useDispatch, useSelector } from "react-redux";
import BestSeller from "../../components/GiftCart/BestSeller";
import { Helmet } from "react-helmet";
import { userAuth } from "../../redux/actions/auth-actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Main = () => {
    const homeData = useSelector((state) => state.homeData);
    const giftCartRef = useRef(null)
    const dispatch = useDispatch()
    const history = useHistory();
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id')
        if (id) {
            localStorage.setItem('uid', id)
            dispatch(userAuth({ id }))
            history.push("/")
        }
    }, [])

    const executeScroll = () => giftCartRef.current.scrollIntoView()
    return (
        <>
            <Helmet>
                <title>{"اولین فروشگاه خرید گیفت کارت آیتونز اپل - گوگل پلی - پلی استیشن - استیم"}</title>
                <meta name="apple-mobile-web-app-title" content="گیفت کارت" />
                <meta name={"description"}
                    content={"خرید گیفت کارت آیتونز اپل امریکا انگلیس استرالیا اروپا و کانادا , گوگل پلی استور , آمازون , پلی استیشن , ps4 , ps5 , psn , اکانت گلد ایکس باکس , مایکروسافت , استیم والت , اسپاتیفای , ویزا کارت , مستر کارت , امریکن اکسپرس , نتفلیکس , اسکایپ , مينت , ارزان , اپل آیدی , ریزر گلد , یوسی پابجی"} />
                <meta name={"keywords"} content={"playstation,sony,steam,google play,google Cart"} />
            </Helmet>
            <Slider executeScroll={executeScroll} />
            <div className="Main_content">
                <GameCart homeData={homeData} />
                <GiftCart refScroll={giftCartRef} homeData={homeData} title={true} />
                {/* <BestSeller homeData={homeData} title={true} /> */}
            </div>
        </>
    );
};

export default Main;
