import React from "react";
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1, height: "100vh" }}>
                <h2>مشکلی پیش آمد!</h2>
            </div>
        }

        return this.props.children;
    }
}
export default ErrorBoundary